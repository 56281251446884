<template>
  <div>This is transfer data</div>
</template>

<script>
  export default {
    props: ['transferId'],
    data() {
      return {
        transfer: []
      }
    },
    beforeMount() {
      let tran = this.$store.state.Transfers
      for (let i = 0; i < tran.length; i++) {
        if (tran[i].id === this.transferId) {
          this.transfer = tran[i]
          break
        }
      }
    }
  }
</script>
